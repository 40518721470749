export const siteSettings = {
    name: "Kaladhwani Academy of Performing Arts",
    description:
        "We believe that performing and teaching arts are two different skill sets, and hence, we take special care to ensure we hire highly qualified artists who are trained and enjoy both the domains.",
    author: {
        name: "Kaladhwani Academy of Performing Arts",
        websiteUrl: "https://kaladhwani.com",
        address: "",
    },
    defaultLanguage: "en",
    currencyCode: "INR",
    site_header: {
        menu: [
            {
                id: 1,
                path: "",
                label: "Courses",
                subMenu: [
                    {
                        id: 1,
                        path: "/learn-bharatanatyam-online",
                        label: "Bharatanatyam",
                    },
                    {
                        id: 2,
                        path: "/learn-hindustani-music-online",
                        label: "Hindustani Music",
                    },
                ],
            },
            {
                id: 2,
                path: "",
                anchor: "/#meet-your-teachers",
                label: "Our Teachers",
            },
            {
                id: 3,
                path: "/become-a-teacher",
                label: "Become a Teacher",
            },
            {
                id: 4,
                path: "",
                label: "Contact Us",
                subMenu: [
                    {
                        id: 1,
                        path: "mailto:radhika@kaladhwani.com",
                        label: "Email Us",
                        icon: "/images/gmail.svg",
                        alt: "Gmail",

                    },
                    {
                        id: 2,
                        path: "https://wa.me/918373989201",
                        label: "Whatsapp Us",
                        icon: "/images/whatsapp.svg",
                        alt: "Whatsapp",
                    },
                    {
                        id: 3,
                        path: "https://rb.gy/r8rlt9",
                        label: "Locate Our Studio",
                        icon: "/images/google-maps.svg",
                        alt: "Google Map",
                    },
                ],
            },
        ],
        mobileMenu: [
            {
                id: 1,
                path: "",
                label: "Courses",
                subMenu: [
                    {
                        id: 1,
                        path: "/learn-bharatanatyam-online",
                        label: "Bharatanatyam",
                    },
                    {
                        id: 2,
                        path: "/learn-hindustani-music-online",
                        label: "Hindustani Music",
                    },
                ],
            },
            {
                id: 2,
                path: "",
                anchor: "/#meet-your-teachers",
                label: "Our Teachers",
            },
            {
                id: 3,
                path: "/become-a-teacher",
                label: "Become a Teacher",
            },
            {
                id: 4,
                path: "",
                label: "Contact Us",
                subMenu: [
                    {
                        id: 1,
                        path: "mailto:radhika@kaladhwani.com",
                        label: "Email Us",
                        icon: "/images/gmail.svg",
                        alt: "Gmail",

                    },
                    {
                        id: 2,
                        path: "https://wa.me/918373989201",
                        label: "Whatsapp Us",
                        icon: "/images/whatsapp.svg",
                        alt: "Whatsapp",
                    },
                    {
                        id: 3,
                        path: "https://rb.gy/r8rlt9",
                        label: "Locate Our Studio",
                        icon: "/images/google-maps.svg",
                        alt: "Google Map",
                    },
                ],
            },
        ],
    },
};

import React from "react";
import { Link } from "gatsby";
export default function Footer() {
  return (
    <div className="flex flex-col bg-lightGrey lg:pl-28 lg:pr-20 md:px-12 px-8 pt-16 pb-8">
      <div className="md:grid lg:grid-cols-footer md:grid-cols-2 flex flex-row flex-wrap lg:grid-rows-1 lg:gap-20 md:gap-12 auto-rows-min justify-start items-start">
        <div className="flex flex-col md:mb-0 mb-9">
          <Link to="/" className="pb-2 flex justify-start items-start -mt-8">
            <img
              src={"/images/KD.png"}
              alt="Kaladhwani"
              className="lg:w-40 md:w-auto md:h-16 h-12 flex justify-start items-start object-contain text-left lg:h-auto"
            />
          </Link>
          <p className="text-sm leading-none mb-0 text-footerText">
            Kaladhwani is a social, charitable trust that promotes performing
            arts of India, to the youth, thereby unearthing talent in the
            country to create future talents of the world. Your money donated to
            us, not just gets you income tax exemption but also provides access
            to under-privileged children and gives them an opportunity to find
            their voice in the arts.{" "}
          </p>
        </div>
        <div className="flex flex-col justify-start items-start md:w-full w-6/12">
          <Heading>Explore Kaladhwani</Heading>
          <ActionLink className="mb-4" to="/">
            Kaladhwani Academy
          </ActionLink>
          <ActionLink className="mb-4" to="/learn-bharatanatyam-online">
            Learn Bharatanatyam
          </ActionLink>
          <ActionLink className="mb-0" to="/learn-hindustani-music-online">
            Learn Hindustani Music
          </ActionLink>
        </div>
        <div className="flex flex-col justify-start items-start md:w-full w-6/12 md:pl-0 pl-4">
          <Heading>Follow Us</Heading>
          <a
            href="https://instagram.com/kaladhwani?utm_medium=copy_link"
            target="_blank"
            className="flex justify-start items-center mb-3 last:mb-0 group"
          >
            <img src="/images/instagram.svg" alt="" className="mr-2 w-5"></img>
            <Text className="group-hover:underline group-hover:text-black">
              Instagram
            </Text>
          </a>
          <a
            href="https://www.facebook.com/kaladhwani19"
            target="_blank"
            className="flex justify-start items-center mb-3 last:mb-0 group"
          >
            <img src="/images/facebook.svg" alt="" className="mr-2 w-5"></img>
            <Text className="group-hover:underline group-hover:text-black">
              Facebook
            </Text>
          </a>
          <a
            href="https://www.youtube.com/channel/UCt0D0_IctIYDpTivpKrqO3g"
            target="_blank"
            className="flex justify-start items-center mb-3 last:mb-0 group"
          >
            <img
              src="/images/youtube-footer.svg"
              alt=""
              className="mr-2 w-5"
            ></img>
            <Text className="group-hover:underline group-hover:text-black">
              Youtube
            </Text>
          </a>
          <a
            href="https://rb.gy/r8rlt9"
            target="_blank"
            className="flex justify-start items-center mb-3 last:mb-0 group"
          >
            <div className="border-1 border-footerText rounded-sm w-5 h-5 p-0.5 flex justify-center items-cente mr-3">
              <img src="/images/google-reviews.svg" alt="" className=""></img>
            </div>
            <Text className="group-hover:underline group-hover:text-black">
              Locate our studio
            </Text>
          </a>
        </div>
        <div className="flex flex-col justify-start items-start md:mt-0 mt-6">
          <Heading>Contact Us</Heading>
          <a
            className="flex justify-start items-center last:mb-0 group mb-3"
            href="https://wa.me/918373989201"
            target="_blank"
          >
            <img src="/images/message.svg" alt="" className="mr-2 w-5"></img>
            <Text className="group-hover:underline group-hover:text-black">
              Leave Us a Message
            </Text>
          </a>
          <div className="flex justify-start items-center last:mb-0 mb-3">
            <img src="/images/phone.svg" alt="" className="mr-2 w-5"></img>
            <Text>
              <span className="font-bold">Phone number:</span> +91 8373989201
            </Text>
          </div>
          <div className="flex justify-start items-start last:mb-0 mb-3">
            <img
              src="/images/location.svg"
              alt=""
              className="mr-2 w-5 -mt-1"
            ></img>
            <Text>
              <span className="font-bold">Address:</span> Ground Floor, NL-002,
              Sector 116, Noida, Uttar Pradesh 201301
            </Text>
          </div>
          <a
            href="mailto:radhika@kaladhwani.com"
            target="_blank"
            className="flex justify-start items-start last:mb-0 group"
          >
            <img src="/images/email.svg" alt="" className="mr-2 w-5"></img>
            <Text className="mt-1 group-hover:underline group-hover:text-black">
              <span className="font-bold">Email id:</span>{" "}
              radhika@kaladhwani.com
            </Text>
          </a>
        </div>
      </div>
      <div className="flex lg:flex-row flex-col items-start justify-between w-full mt-8 lg:gap-4">
        <div className="lg:hidden">
          <Heading>Policies</Heading>
        </div>
        <div className="flex md:flex-row flex-col justify-end md:items-center lg:gap-8 md:gap-6 gap-3">
          <ActionLink to="/policies#terms">Terms of Use</ActionLink>
          <ActionLink to="/policies#privacy">Privacy Policy</ActionLink>
          <ActionLink to="/policies#refund">
            Refund/Cancellation Policy
          </ActionLink>
        </div>
        <Text className="text-left lg:text-right lg:mt-0 mt-8">
          Copyright kaladhwani © 2021. All Rights Reserved
        </Text>
      </div>
    </div>
  );
}
const Text = (props) => {
  return (
    <p
      className={`text-sm leading-none text-footerText ${props.className} last:mb-0 transition-all duration-300 ease`}
    >
      {props.children}
    </p>
  );
};
const ActionLink = (props) => {
  return (
    <Link
      to={props.to}
      className={`hover:underline hover:text-black transition-all duration-300 ease text-sm leading-none text-footerText ${props.className} last:mb-0`}
    >
      {props.children}
    </Link>
  );
};
const ExternalLink = (props) => {
  return (
    <a
      href={props.href}
      target="_blank"
      className={`text-sm leading-none text-footerText ${props.className} last:mb-0`}
    >
      {props.children}
    </a>
  );
};

const Heading = (props) => {
  return (
    <h4 className="text-black font-bold text-base md:mb-3 mb-2">
      {props.children}
    </h4>
  );
};

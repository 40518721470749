import React from 'react'
import { Link } from 'gatsby';
import { Chevron } from "../../utils/icons/chevron";
const activeClassName = "text-red font-semibold opacity-100";
const linkClass = "flex items-center justify-start text-sm text-black xl:text-sm px-3 xl:px-4 py-2 relative focus:outline-none";
export default function ListMenu(props) {
	const data = props.data
	return (
		<li className="relative lg:hover:bg-mustard">
			{data.icon ? <a
				href={data.path}
				target="_blank"
				activeClassName={activeClassName}
				className={linkClass}
			>
				{data.icon && <img src={data.icon} alt={data.alt} className="w-4 h-4 mr-4"></img>}
				{data.label}
				{data.subMenu && (
					<span className="text-sm mt-0 shrink-0">
						<Chevron className="transition duration-300 ease-in-out transform group-hover:-rotate-180" />
					</span>
				)}
			</a>
				: <Link
					to={data.path}
					activeClassName={activeClassName}
					className={linkClass}
				>
					{data.icon && <img src={data.icon} alt={data.alt} className="w-4 h-4 mr-4"></img>}
					{data.label}
					{data.subMenu && (
						<span className="text-sm mt-0 shrink-0">
							<Chevron className="transition duration-300 ease-in-out transform group-hover:-rotate-180" />
						</span>
					)}
				</Link>
			}
			{props.hasSubMenu && (
				<SubMenu dept={props.dept} data={data.subMenu} menuIndex={props.menuIndex} />
			)}
		</li>
	)
}
const SubMenu = ({ dept, data, menuIndex }) => {
	dept = dept + 1;
	return (
		<ul className="subMenuChild shadow-subMenu absolute z-0 right-full 2xl:right-auto 2xl:left-full opacity-100 invisible w-56" style={{ backgroundColor: "#F3F3F3" }}>
			{data?.map((menu, index) => {
				const menuName = `sidebar-submenu-${dept}-${menuIndex}-${index}`;

				return (
					<ListMenu
						dept={dept}
						data={menu}
						hasSubMenu={menu.subMenu}
						menuName={menuName}
						key={menuName}
						menuIndex={index}
					/>
				);
			})}
		</ul>
	);
};

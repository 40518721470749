import React, { useState, useEffect } from 'react'
import Logo from '../../utils/Logo'
import { Link } from 'gatsby';
import HeaderMenu from './HeaderMenu';
import Button from '../../utils/Button';
import MobileMenu from './MobileMenu';
import { siteSettings } from "../../data/site-settings";
import NavlinkBlock from './NavlinkBlock';
const { site_header } = siteSettings;

export default function Header(props) {

    const genericHamburgerLine = `h-1 w-full my-0.5 rounded-full bg-black transition ease transform duration-300`;
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        setIsOpen(false)
    }, []);

    const toggleNav = () => {
        setIsOpen(!isOpen)
    };
    return (
        <header className="w-full bg-white flex flex-col sticky top-0 z-50">
            <div className="md:h-20 h-16 flex lg:px-28 md:px-12 px-4 w-full">
                <div className="flex items-center mx-auto w-full h-full relative">
                    <button
                        className="absolute left-0 mr-4 lg:hidden right-4 flex flex-col h-12 w-6 rounded justify-center items-center group outline-none overflow-hidden focus:outline-none "
                        onClick={toggleNav}
                    >
                        <div
                            className={`${genericHamburgerLine} mr-auto ${isOpen
                                ? "rotate-45 translate-y-2 translate-x-0 opacity-100 group-hover:opacity-100"
                                : "opacity-100 group-hover:opacity-100"
                                }`}
                        />
                        <div
                            className={`${genericHamburgerLine} w-full ${isOpen ? "opacity-0 -translate-x-8" : "opacity-100 group-hover:opacity-100"
                                } `}
                        />
                        <div
                            className={`${genericHamburgerLine} ml-auto ${isOpen
                                ? "-rotate-45 -translate-y-2 -translate-x-0 opacity-100 group-hover:opacity-100"
                                : "opacity-100 group-hover:opacity-100 "
                                } `}
                        />
                    </button>
                    <Logo />
                    <HeaderMenu
                        data={site_header.menu}
                        className="hidden ml-auto lg:flex"
                    />
                    <div className="mr-0 ml-auto hidden lg:flex">
                        <Button type="internalLink" to="/book-free-trial">Book a FREE Trial</Button>
                    </div>
                </div>
            </div>
            <div className={`${isOpen ? 'max-h-[500px] opacity-100' : 'max-h-[0px] opacity-100'} w-full h-auto transition-all duration-300 ease-in bg-white lg:hidden flex flex-col justify-center items-center fixed md:top-20 top-16 pl-4 overflow-hidden`}>
                {/* {site_header.mobileMenu?.map((item) => (
                    <NavlinkBlock key={"footerNav" + item.id} data={item} external={item.icon ? true : false}>Home</NavlinkBlock>
                ))} */}
                <MobileMenu />
            </div>
        </header>
    )
}

import * as React from "react";
import { useState } from "react";
import { Link } from "gatsby";
import { Chevron } from "../../utils/icons/chevron";
import { siteSettings } from "../../data/site-settings";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Listmenu from "./ListMenu";

const { site_header } = siteSettings;

export default function MobileMenu(props) {
    const [activeMenus, setActiveMenus] = useState([]);
    const handleArrowClick = (menuName) => {
        let newActiveMenus = [...activeMenus];
        if (newActiveMenus.includes(menuName)) {
            var index = newActiveMenus.indexOf(menuName);
            if (index > -1) {
                newActiveMenus.splice(index, 1);
            }
        } else {
            newActiveMenus.push(menuName);
        }

        setActiveMenus(newActiveMenus);
    };

    const ListMenu = ({
        dept,
        data,
        hasSubMenu,
        menuName,
        menuIndex,
        className = "",
    }, props) =>
        data.label && (
            <li className={`mb-0.5 ${className} w-full`}>
                <div className="flex datas-center justify-between w-full pr-4 md:pr-8">
                    {
                        data.path ?
                            <Link
                                to={data.path}
                                className="inline-flex datas-center w-full text-base opacity-80 text-black hover:opacity-100 xl:text-base px-3 xl:px-4 py-2 relative focus:outline-none group"
                                activeClassName="text-red opacity-100 font-semibold"
                            >
                                {data.label}
                                {(data?.columns || data.subMenu) && (
                                    <span className="text-xs -mt-1 w-3 flex justify-end ml-auto" onClick={() => handleArrowClick(menuName)}>
                                        <img src="images/chevron.svg" alt="" className={`transition duration-300 ${activeMenus.includes(menuName) ? "rotate-0" : "rotate-180"} ease-in-out transform`} />
                                        {/* <Chevron className="transition duration-300 ease-in-out transform group-hover:-rotate-180" /> */}
                                    </span>
                                )}
                            </Link>
                            :
                            data.anchor ?
                                <AnchorLink to={data.anchor} title={data.label} onClick={props.handleAnchorClick}>
                                    <p
                                        className="inline-flex datas-center w-full text-base opacity-80 text-black hover:opacity-100 xl:text-base px-3 xl:px-4 py-2 relative focus:outline-none group"
                                        activeClassName="text-red opacity-100 font-semibold"

                                    >
                                        {data.label}
                                        {(data?.columns || data.subMenu) && (
                                            <span className="text-xs -mt-1 w-3 ml-auto flex justify-end" onClick={() => handleArrowClick(menuName)}>
                                                <img src="images/chevron.svg" alt="" className={`transition duration-300 ${activeMenus.includes(menuName) ? "rotate-0" : "rotate-180"} ease-in-out transform`} />
                                                {/* <Chevron className="transition duration-300 ease-in-out transform group-hover:-rotate-180" /> */}
                                            </span>
                                        )}
                                    </p>
                                </AnchorLink>
                                :
                                <div
                                    className="inline-flex datas-center w-full text-base opacity-80 text-black hover:opacity-100 xl:text-base px-3 xl:px-4 py-2 relative focus:outline-none group"
                                    activeClassName="text-red opacity-100 font-semibold"
                                    onClick={() => handleArrowClick(menuName)}
                                >
                                    {data.label}
                                    {(data?.columns || data.subMenu) && (
                                        <span className="text-xs -mt-1 w-3 flex justify-end ml-auto">
                                            <img src="images/chevron.svg" alt="" className={`transition duration-300 ${activeMenus.includes(menuName) ? "rotate-0" : "rotate-180"} ease-in-out transform`} />
                                            {/* <Chevron className="transition duration-300 ease-in-out transform group-hover:-rotate-180" /> */}
                                        </span>
                                    )}
                                </div>
                    }

                </div>
                {hasSubMenu && (
                    <SubMenu
                        dept={dept}
                        data={data.subMenu}
                        toggle={activeMenus.includes(menuName)}
                        menuIndex={menuIndex}
                    />
                )}
            </li>
        );
    const handleAnchorClick = () => {
        console.log("handoe clidked");
    }

    const SubMenu = ({ dept, data, toggle, menuIndex }) => {
        if (!toggle) {
            return null;
        }

        dept = dept + 1;
        return (
            <ul className="pt-0.5 pl-6">
                {data?.map((menu, index) => {
                    const menuName = `sidebar-submenu-${dept}-${menuIndex}-${index}`;

                    return (
                        <Listmenu
                            dept={dept}
                            data={menu}
                            hasSubMenu={menu.subMenu}
                            menuName={menuName}
                            key={menuName}
                            menuIndex={index}
                            className={dept > 1 && "ps-4"}
                        />

                    );
                })}
            </ul>
        );
    };

    return (
        <>
            <div className="flex flex-col justify-between w-full h-full">
                <div className="menu-scrollbar flex-grow mb-auto">
                    <div className="flex flex-col py-7 px-0 lg:px-2 text-heading">
                        <ul className="mobileMenu">
                            {site_header.mobileMenu.map((menu, index) => {
                                const dept = 1;
                                const menuName = `sidebar-menu-${dept}-${index}`;

                                return (
                                    <ListMenu
                                        dept={dept}
                                        data={menu}
                                        hasSubMenu={menu.subMenu}
                                        menuName={menuName}
                                        key={menuName}
                                        menuIndex={index}
                                        handleAnchorClick={handleAnchorClick}
                                    />
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

import React from 'react'
import { Link } from 'gatsby'
import ListMenu from './ListMenu'
// import { Chevron } from "../../utils/icons/chevron";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";
export default function HeaderMenu(props) {
    const data = props.data
    console.log(props.anchor)
    return (
        <nav className={`headerMenu flex w-auto relative ml-12 ${props.className}`}>
            {data?.map((item) => (
                <div
                    className={`menuItem group cursor-pointer py-7 ${item.subMenu ? "relative" : ""
                        }`}
                    key={item.id}
                >
                    {
                        item.path ?
                            <Link
                                to={item.path}
                                className="inline-flex items-center text-sm opacity-80 text-black hover:opacity-100 xl:text-sm px-3 xl:px-4 py-2 relative focus:outline-none group"
                                activeClassName="text-red opacity-100 font-semibold"
                            >
                                {item.label}
                                {(item?.columns || item.subMenu) && (
                                    <span className="text-xs mt-1 xl:mt-0.5 w-4 flex justify-end">
                                        <img src="images/chevron.svg" alt="" className="transition duration-300 rotate-180 ease-in-out transform group-hover:rotate-0" />
                                        {/* <Chevron className="transition duration-300 ease-in-out transform group-hover:-rotate-180" /> */}
                                    </span>
                                )}
                            </Link>
                            :
                            item.anchor ?
                                <AnchorLink to={item.anchor} title={item.label}>
                                    <p
                                        className="inline-flex items-center text-sm opacity-80 text-black hover:opacity-100 xl:text-sm px-3 xl:px-4 py-2 relative focus:outline-none group"
                                        activeClassName="text-red opacity-100 font-semibold"
                                    >
                                        {item.label}
                                        {(item?.columns || item.subMenu) && (
                                            <span className="text-xs mt-1 xl:mt-0 w-4 flex justify-end">
                                                <img src="images/chevron.svg" alt="" className="transition duration-300 rotate-180 ease-in-out transform group-hover:rotate-0" />
                                                {/* <Chevron className="transition duration-300 ease-in-out transform group-hover:-rotate-180" /> */}
                                            </span>
                                        )}
                                    </p>
                                </AnchorLink>
                                :
                                <div
                                    className="inline-flex items-center text-sm opacity-80 text-black hover:opacity-100 xl:text-sm px-3 xl:px-4 py-2 relative focus:outline-none group"
                                >
                                    {item.label}
                                    {(item?.columns || item.subMenu) && (
                                        <span className="text-xs mt-1 xl:mt-0.5 w-4 flex justify-end">
                                            <img src="images/chevron.svg" alt="" className="transition duration-300 rotate-180 ease-in-out transform group-hover:rotate-0" />
                                            {/* <Chevron className="transition duration-300 ease-in-out transform group-hover:-rotate-180" /> */}
                                        </span>
                                    )}
                                </div>

                    }
                    {item?.subMenu && Array.isArray(item.subMenu) && (
                        <div className="subMenu shadow-dropdown bg-lightGrey min-w-44 w-max h-auto absolute left-0 opacity-0 group-hover:opacity-100">
                            <ul className="text-body text-sm rounded-lg">
                                {item.subMenu.map((menu, index) => {
                                    const dept = 1;
                                    const menuName = `sidebar-menu-${dept}-${index}`;

                                    return (
                                        <ListMenu
                                            dept={dept}
                                            data={menu}
                                            hasSubMenu={menu.subMenu}
                                            menuName={menuName}
                                            key={menuName}
                                            menuIndex={index}
                                        />
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                </div>
            ))}
        </nav>
    )
}
import React from 'react'
import { Link } from "gatsby"
export default function Logo() {
    return (
        <Link to="/" className="py-2 flex justify-start items-start lg:m-0 m-auto">
            <img src={"/images/KD.svg"} alt="Kaladhwani" className="lg:w-36 md:w-auto md:h-16 h-12 flex justify-start items-start object-contain text-left lg:h-auto" />
        </Link>

    )
}

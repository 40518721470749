import React, { forwardRef } from "react";
import { Link } from "gatsby";
const Button = forwardRef((props, ref) => {
    const {
        variant = props.variant,
        children,
        active,
        loading = false,
        disabled = false,
        ...rest
    } = props;
    const classes = {
        root:
            "button relative w-auto pt-3 pb-2 px-4 font-bold rounded-md py-2 flex justify-center items-center bg-red text-white md:text-sm text-sm cursor-pointer transition duration-0 hover:bg-darkmustard",
    };
    const rootClassName = classes.root;
    return (
        props.type === "externalLink" ?
            <a
                ref={ref}
                className={rootClassName}
                disabled={disabled}
                href={props.href}
                target="_blank"
                rel="noreferrer"
                {...rest}
            >
                {props.children}
            </a>
            :
            props.type === "internalLink" ?
                <Link
                    ref={ref}
                    className={rootClassName}
                    disabled={disabled}
                    to={props.to}
                    {...rest}
                >
                    {props.children}
                </Link>
                :
                <button
                    aria-pressed={active}
                    data-variant={variant}
                    ref={ref}
                    className={rootClassName}
                    disabled={disabled}
                    onClick={props.onClick}
                    {...rest}
                >
                    {props.children}
                </button>
    )
});
export default Button;